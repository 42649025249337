@import '../../../App.scss';
@import 'src/colors.scss';

.tags-page-main-container {
  padding: 20px;
  margin-top: 70px;
  text-align: center;
}

.switch-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;
  padding-right: 50px;
}

.card-list-style {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr)); //
  grid-auto-rows: auto;
  text-align: left;
  margin-block-start: 1rem;
  counter-reset: grid;
  grid-auto-flow: dense;
  justify-content: flex-start;

  max-height: calc(4 * (18rem + 10px));
  overflow-y: auto;
}

.text-left {
  text-align: left;
  margin: 1rem;
}
