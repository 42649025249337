@import 'src/colors.scss';

.main-page {
  padding-top: 90px;
  padding-left: 50px;
  padding-right: 50px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-breadcrumbs-wrapper {
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.company-name {
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: inherit;
  font: inherit; 

  &:hover {
    color: $navy;
  }
}

