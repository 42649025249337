@import '../../../../App.scss';

.user-icon {
  background-color: transparent !important;
  margin-right: 0px;
}

.tag {
  max-width: 180px;
}

.tag-label {
  word-wrap: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
}