.download-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonText {
  width: 100px;
  text-decoration: none;
}

.buttonText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.icon-enabled {
  color: rgb(31, 78, 110);
  margin-left: 10px;
}

.icon-disabled {
  color: grey;
  margin-left: 10px;
}
