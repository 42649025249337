@import '../../../App.scss';

.main-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 86px - 72px);
  width: 100%;
  position: relative;
  top: 80px;
  padding-top: 20px;
  padding-bottom: 100px;
}

.title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.lt-profile-tags-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dept-info {
  padding-left: 25%;
  padding-right: 25%;
}

.create-lt-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
  z-index: 1;
}

.saveAndCloseButtons {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.infoPencil :hover {
  color: lightgray;
}

.delete-leadership-button {
  position: fixed;
  bottom: 25px;
  right: 40px;
}

.cfa-multiselect-content .cfa-tag.tag-default {
  background-color: rgba(red($primary), green($primary), blue($primary), 0.1);
  border-color: rgba(red($primary), green($primary), blue($primary), 0.2);
}

.cfa-multiselect-content .cfa-tag.tag-default .tag-close {
  background: transparent;
}

.navigation-breadcrumbs-wrapper {
  align-self: flex-start;
  display: flex;
  align-items: center;
}
